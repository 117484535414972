<template>
  <div>
    <h2>กรุณารอสักครู่.....</h2>
    <iframe
      v-if="htmlContent"
      :src="htmlContent"
      width="1000"
      height="600"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      htmlContent: null
    }
  },
  methods: {
    async getLoginUrl() {
      const { casino, gameId } = this.$route.query
      const url = await this.$http.post(`member/gameplay/login`, {
        provider: casino,
        gameId,
        tab: 'CASINO'
      })

      console.log(url.data)
      if (url.data.result.code === 0) {
        console.log(url.data.result.data.uri)
        // this.iframeSrc = url.data.result.data.uri
        // window.open(url.data.result.data.uri, '_blank')
        // const resp = await this.renderBaccarat(url.data.result.data.uri)
        // console.log(resp)
        // this.htmlContent = `data:text/html;charset=utf-8, + ${encodeURI(
        //   resp.data.html
        // )}`
        console.log(url.data.result.data.uri)
        // window.open(url.data.url.url, '_blank')
        setTimeout(() => {
          window.location.assign(url.data.result.data.uri)
        }, 100)
      } else {
        alert('ไม่สามารถเข้าสู่หน้าเล่นได้ชั่วคราว')
        window.history.back()
      }
    },
    async renderBaccarat(uri) {
      const resp = await this.$http.post(`member/render/baccarat`, {
        uri
      })
      return resp
    }
  },
  async beforeMount() {
    await this.getLoginUrl()
  }
}
</script>
